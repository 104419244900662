import React from "react";
import { Section } from "./styled";

const SectionWrap = ({ type, cardStyle, color, textColor, children }) => {
  return (
    <Section type={type} cardStyle={cardStyle} color={color} textColor={textColor}>
      {children}
    </Section>
  );
};

export default SectionWrap;
