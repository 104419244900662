import styled from "@emotion/styled";
import { colors } from "styles/colors";
import { ButtonWrap } from "../../../shared/Button/styled";

const _mobileMedia = `600px`;

export const ContentWrap = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1080px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 150px 15px;

  @media (max-width: ${_mobileMedia}) {
    flex-direction: column-reverse;
    padding-top: 15px;
    padding-bottom: 30px;

    ${ButtonWrap} {
      width: 100%;
    }
  }
`;

export const Control = styled.div`
  margin-left: 10px;
  cursor: pointer;
  .icon {
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    fill: ${colors.WHITE};
    stroke: none;
  }
`;

export const VideoControls = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 15px;
  right: 15px;
  @media (min-width: 769px) {
    top: auto;
    bottom: 15px;
    right: 15px;
  }
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const ContentBlock = styled.div`
  pozition: relative;
  z-index: 2;
  max-width: 400px;
  margin-right: 20px;

  @media (max-width: ${_mobileMedia}) {
    max-width: 524px;
    margin-right: 0;
    margin-top: 40px;
  }
`;

export const MainTitle = styled.h1`
  margin: 0;
  font-size: 36px;
  line-height: 42px;
  color: ${colors.WHITE};
`;

export const Desc = styled.div`
  margin: 18px 0;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.WHITE};

  & > p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  @media (max-width: ${_mobileMedia}) {
    margin: 20px 0 24px;
  }
`;

export const Container = styled.section`
  position: relative;
`;
