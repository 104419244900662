import styled from "@emotion/styled";
import { colors, colorsRGB } from "styles/colors";
import { SliderWrapper, ArrowNext, ArrowPrev, Item } from "shared/Slider/styled";
import { CardTime, Card } from "styles/MainCards.styled.js";

/* Style Section */
export const Section = styled.div`
  background-color: ${(props) => props.type === "primary" && (props.color || colors.RED)};
  color: ${(props) => props.type === "primary" && (props.textColor || colors.WHITE)};

  ${(props) =>
    props.type === "primary"
      ? `
      padding-bottom: 50px;
      ${CardTime}{
        color: inherit;
      }
      ${ArrowNext},
      ${ArrowPrev} {
        .icon{
          stroke: ${colors.WHITE};
        }
        &:hover{
          .icon{
          stroke: rgba(${colorsRGB.WHITE}, 0.6);
        }
        }
      }
      ${Item}{
        &:before {
          background-color: ${colors.WHITE};
        }
      }
      ${Card} {
       ${
         props.cardStyle === "standart" &&
         `
        color: ${colors.GREY_DARK};
        span:before {
          background-color: ${colors.GREY_SEMIDARK};
        }
        .icon-savedslide {
          stroke: ${colors.WHITE};
          fill: ${colors.WHITE};
        }
        .icon-saveslide {
          stroke: ${colors.WHITE}
        }
        `
       }
      @media (max-width: 1430px) {
        ${SliderWrapper}{
          .slick-list {
            &:after {
                background: 
                linear-gradient(
                  270deg, rgba(${colorsRGB.RED}, 0.6) 0%, 
                  rgba(${colorsRGB.RED}, 0) 95.24%
                );
              }
          }
        }        
      }
    `
      : `
      padding-bottom: 50px;
    `}

  @media (min-width: 769px) {
    padding-bottom: 41px;
  }
`;
