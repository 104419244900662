const PREPOSITIONS = [
  "a",
  "an",
  "the",
  "about",
  "above",
  "across",
  "after",
  "against",
  "along",
  "among",
  "around",
  "at",
  "away (from)",
  "before",
  "behind",
  "below",
  "beneath",
  "beside",
  "between",
  "by",
  "close to",
  "down",
  "for",
  "from",
  "in",
  "in front of",
  "inside",
  "into",
  "near",
  "next to",
  "off",
  "on",
  "onto",
  "opposite",
  "out of",
  "outside",
  "over",
  "past",
  "round",
  "through",
  "throughout",
  "to",
  "towards",
  "under",
  "underneath",
  "up",
  "with",
  "within",
  "without",
];

export const toCapitalLetterTitle = (title) => {
  if (!title) return;
  const wordsArr = title.split(" ");
  const wordsWithCapital = wordsArr.map((word) => {
    if (PREPOSITIONS.some((prep) => prep === word)) return word;
    else if (word) return word[0].toUpperCase() + word.slice(1);
    else return "";
  });
  return wordsWithCapital.join(" ");
};

export const convertDescription = (description) => {
  if (!description) return;
  if (description.length > 151) return `${description.substr(0, 151)} ...`;
  else return description;
};
