import styled from "@emotion/styled";
import { colors } from "styles/colors";
import { ButtonWrap } from "../../../shared/Button/styled";

const _mobileMedia = `600px`;

export const Root = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 25px;

  @media (max-width: ${_mobileMedia}) {
    flex-direction: column-reverse;
    padding-top: 0;
    padding-bottom: 0;

    ${ButtonWrap} {
      width: 100%;
    }
  }
`;

export const ContentBlock = styled.div`
  max-width: 400px;
  margin-right: 20px;

  @media (max-width: ${_mobileMedia}) {
    max-width: 524px;
    margin-right: 0;
    margin-top: 40px;
  }
`;

export const MainTitle = styled.h1`
  margin: 0;
  font-size: 36px;
  line-height: 42px;
  color: ${colors.GREY_DARK};
`;

export const Desc = styled.div`
  margin: 18px 0;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.GREY_SEMIDARK};

  & > p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  @media (max-width: ${_mobileMedia}) {
    margin: 20px 0 24px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 524px;
  max-height: 524px;
`;

export const ImageResizer = styled.div`
  position: relative;
  @media (min-width: 769px) {
    padding-bottom: 100%;
  }
`;
