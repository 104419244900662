import styled from "@emotion/styled";
import { fonts } from "styles/fonts";
import { colors, colorsRGB } from "styles/colors";
import { ButtonWrap } from "shared/Button/styled";

export const MenuWrap = styled.div`
  nav {
    display: block;
  }
`;
export const Title = styled.h2`
  margin: 45px 0 0;
  font-family: ${fonts.abhayaRegular};
  font-size: ${({ isBig }) => (isBig ? `42px` : `30px`)};
  letter-spacing: -0.4px;
  color: ${colors.HOME_HEADERS};
  margin-bottom: ${(props) => !props.isSub && "21px"};
`;

export const SubTitle = styled.p`
  margin: 0 0 15px;
  font-family: ${fonts.latoRegular};
  color: rgba(32, 36, 41, 0.75);

  @media (min-width: 769px) {
    margin: 0 0 24px;
    font-size: 18px;
  }
`;
export const PreviewImg = styled.div`
  height: 484px;
  width: 100%;
  background-position: 50%;
  background-size: cover;
  margin-bottom: 15px;
  border-radius: 20px;
  background-image: url("/images/preview.jpg");
  background-repeat: no-repeat;
  @media (max-width: 769px) {
    margin-bottom: 0;
    order: 100;
    flex: 1 0 auto;
    height: 328px;
  }
  @media (max-width: 540px) {
    background-image: url("/images/preview_small.jpg");
  }
`;

/* Style Intro */
export const IntroWrap = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 769px) {
    flex-direction: column-reverse;
  }
`;
export const IntroTitle = styled.h1`
  font-family: ${fonts.abhayaBold};
  font-size: 36px;
  line-height: 1.15;
  margin-bottom: 24px;
  @media (min-width: 769px) {
    margin-bottom: 16px;
  }
`;
export const IntroBody = styled.div`
  letter-spacing: -0.01px;
  @media (min-width: 769px) {
    max-width: 365px;
    width: 100%;
  }
  p {
    margin: 0;
    color: ${colors.GREY_SEMIDARK};
    font-family: ${fonts.latoMedium};
  }
  p + p {
    margin-top: 5px;
  }
  + ${ButtonWrap} {
    margin-top: 21px;
  }
`;
export const Intro = styled.div`
  padding-top: 30px;
  width: 100%;
  @media (min-width: 769px) {
    display: none;
    padding-top: 30px;
    padding-bottom: 30px;
    margin: auto 0;
  }
  ${ButtonWrap} {
    font-size: 16px;
    display: none;
    @media (min-width: 769px) {
      display: inline-block;
    }
  }
`;

/* Style Search Form */
export const SearchForm = styled.div`
  display: flex;

  @media (min-width: 769px) {
    ${(props) => !props.visible && "display: none;"};
  }

  input[type="search"] + ${ButtonWrap} {
    margin-left: 10px;
    min-width: 46px;

    @media (min-width: 769px) {
      margin-left: 13px;
      min-width: inherit;
    }
  }

  @media (max-width: 769px) {
    margin-top: 30px;
  }

  ${ButtonWrap} {
    padding: 9px;
    font-size: 0;
    line-height: 0.7em;
    .icon {
      margin-left: 0;
    }
    @media (min-width: 769px) {
      font-size: 1rem;
      padding: 11px;
      .icon {
        margin-left: 10px;
      }
    }
  }
`;

export const NoLogined = styled.div``;

/* Style Content */
export const Content = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  > * + * {
    padding-top: 0 !important;
  }

  @media (min-width: 1024px) {
    max-width: calc(100% - 220px);
  }
`;

/* Style Row */
export const Row = styled.div`
  ${({ isSelebrity }) => !isSelebrity && `padding-top: 10px`};
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: 769px) {
    ${({ isSelebrity }) => !isSelebrity && `padding-top: 40px`};
    margin-left: -20px;
    margin-right: -20px;
  }

  ${Content} {
    @media (max-width: 1400px) and (min-width: 1025px) {
      padding-right: 50px;
      padding-left: 50px;
    }
  }

  &.NoLogined {
    align-items: center;
    flex-direction: column;
    ${Content} {
      max-width: 100%;
    }
    ${PreviewImg} {
      @media (min-width: 769px) {
        height: 524px;
        flex: 0 0 calc(100% - 50.095%);
        max-width: calc(100% - 50.095%);
        background-image: url("/images/previewLogin.jpg");
      }
    }
    ${Intro} {
      @media (min-width: 769px) {
        display: block;
        flex: 1 0 400px;
        max-width: 400px;
        padding-right: 20px;
      }
      ${ButtonWrap} {
      }
    }
    ${IntroWrap} {
      @media (min-width: 769px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
    ${SearchForm} {
      margin: 12px 0 0;
      @media (min-width: 769px) {
        display: flex;
        margin: 45px 0 36px;
      }
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  ${(props) => !props.noWidth && `max-width: 1304px;`}
  flex-grow: 1;
  @media (min-width: 769px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ImageBanner = styled.img`
  display: block;
  height: 100%;
  object-fit: cover;
`;
export const Banner = styled.div`
  height: 227px;
  display: flex !important;
  justify-content: space-between;
  width: 100%;

  background-color: #e5c6ae;
  border-radius: 20px;
  overflow: hidden;
  display: block;
  padding-left: 22px;
  @media (min-width: 970px) {
    padding-left: 42px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 228px;
  width: 280px;
  @media (min-width: 1024px) {
    width: 343px;
  }
  @media (max-width: 560px) {
    width: 50%;
  }
`;

export const Subtitle = styled.div`
  margin-top: 10px;
  font-size: 22px;
  font-family: ${fonts.abhayaRegular};
  @media (max-width: 560px) {
    font-size: 14px;
  }
`;

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(${colorsRGB.HOME_HEADERS}, 0.9);
`;
export const BannerTitle = styled.h5`
  margin-top: 45px;
  font-family: ${fonts.abhayaBold};
  color: ##271310;
  z-index: 2;
  width: 100%;
  font-size: 52px;
  margin-bottom: 0;

  @media (max-width: 1200px) {
    font-size: 42px;
  }

  @media (max-width: 560px) {
    font-size: 28px;
    margin-top: 37px;
  }
`;

export const TextContainer = styled.div`
  @media (max-width: 560px) {
    width: 50%;
  }
`;

export const BannerWrapper = styled.div`
  &:focus {
    outline: none;
    border: none;
  }
`;
