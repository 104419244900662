import React, { useCallback } from "react";
import Button from "../../../shared/Button";
import { colors } from "../../../styles/colors";
import { useRouter } from "next/router";

import { ContentBlock, Desc, ImageContainer, ImageResizer, MainTitle, Root } from "./styled";
import cloudfront from "../../../helpers/cloudfront";
import { getAppropriateBucket } from "../../../helpers/detectBrowser";

export const NotAuthorizedUserBanner = () => {
  const router = useRouter();
  const toRegistration = useCallback(() => router.push("/registration"), []);

  return (
    <Root>
      <ContentBlock>
        <MainTitle>A recipe marketplace for culinary creators</MainTitle>
        <Desc>
          <p>Buy amazing recipes and support your favorite culinary creators.</p>
          <p>No more scrolling through screenshots.</p>
          <p>All your recipes in one place.</p>
          <p>Ad free.</p>
        </Desc>
        <Button onClick={toRegistration} color={colors.RED} stylType="primary" text="Sign Up FREE" />
      </ContentBlock>
      <ImageContainer>
        <ImageResizer>
          <img
            src={cloudfront({
              href: `https://${getAppropriateBucket()}.s3.amazonaws.com/public/home-page.webp`,
              size: "xLarge",
            })}
            alt="foodie banner"
            style={{ borderRadius: "20px" }}
          />
        </ImageResizer>
      </ImageContainer>
    </Root>
  );
};
