import React from "react";
import { NextSeo } from "next-seo";
import { convertDescription, toCapitalLetterTitle } from "helpers/seo/transformSeoData";

const HeadTitle = ({ title, img, description, noIndex }) => {
  return (
    <NextSeo
      title={toCapitalLetterTitle(title)}
      description={convertDescription(description) || "Empowering Culinary Creators and Home chefs"}
      noindex={noIndex}
      nofollow={noIndex}
      openGraph={{
        title: toCapitalLetterTitle(title),
        description: convertDescription(description),
        images: [
          {
            url: img || "/images/links/links_prev.png",
            width: 800,
            height: 600,
            alt: "Og Image Alt",
          },
        ],
      }}
    />
  );
};

export default HeadTitle;
