import React, { useEffect } from "react";
import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";
import { GridWrap } from "emotion-flex-grid";
import HeadTitle from "components/HeadTitle/index";
import Section from "shared/Section";
import { isUserLogin, toggleModal } from "store/loginReducer";
const Menu = dynamic(() => import("components/Menu"));
import { Row, Container, Content } from "styles/HomePage.styled";
const ClickbleBanner = dynamic(() => import("./AutorizedBanner/ClickbleBanner"), { ssr: false });
import { getCard, setCretorPaymentCard } from "store/paymentReducer/paymentActions";
import { getCreatorCard, getBuyerCard } from "api/api";
import { NotAuthorizedUserVideo } from "./NotAuthorizedUserVideo/NotAuthorizedUserVideo";
const CelebrityBanner = dynamic(() => import("shared/Banner/CelebrityBanner"), { ssr: false });

import { NotAuthorizedUserBanner } from "./NotAuthorizedUserBanner/NotAuthorizedUserBanner";
import { setCurrentUser } from "../../store/userReducer/userActions";
import { parseCookies } from "nookies";
import { setSlidersData } from "store/layoutReducer/layoutActions";
import { getSliders, hasSelebrity, hasHomeVideo } from "store/layoutReducer";
import { getOwner } from "store/userReducer/userSelectors";
const HomePageSlider = dynamic(() => import("./Slider"), { ssr: false });

const Main = () => {
  const isLogin = useSelector(isUserLogin);
  const dispatch = useDispatch();
  const owner = useSelector(getOwner);
  const sliders = useSelector(getSliders);
  const isCelebrityActive = useSelector(hasSelebrity);
  const homePageVideo = useSelector(hasHomeVideo);

  const hideModal = (e) => {
    if (e.target.id === "overlay") {
      dispatch(toggleModal(false));
    }
  };

  useEffect(() => {
    const tokenClient = parseCookies().access_token;
    if (tokenClient) {
      dispatch(setCurrentUser()).then(() => {
        if (owner.is_creator) {
          dispatch(setCretorPaymentCard());
          if (owner.creator_payout_method_connected) {
            dispatch(getCard({ request: getCreatorCard, is_buyer: !owner.is_creator }));
          }
        } else if (!owner.is_creator && owner.buyer_payment_method_connected)
          dispatch(getCard({ request: getBuyerCard, is_buyer: !owner.is_creator }));
        dispatch(setSlidersData());
      });
    } else {
      dispatch(setSlidersData());
    }
  }, [owner.id, sliders]);

  useEffect(() => {
    window.addEventListener("click", hideModal);
    return () => {
      window.removeEventListener("click", hideModal);
    };
  }, []);

  return (
    <>
      {isCelebrityActive && <CelebrityBanner />}
      {!isCelebrityActive && !isLogin && homePageVideo ? <NotAuthorizedUserVideo src={homePageVideo} /> : null}
      <Container>
        <HeadTitle title={"Home Page | Foody"} />
        <Row isSelebrity={isCelebrityActive} className={`${isLogin ? "" : "NoLogined"}`}>
          {isLogin && <Menu isSelebrity={isCelebrityActive} />}
          <Content isSelebrity={isCelebrityActive}>
            <Section isLogin={isLogin}>
              <GridWrap maxWidth="1050px">{isCelebrityActive ? null : isLogin ? <ClickbleBanner /> : null}</GridWrap>
              <GridWrap maxWidth="1050px">
                {isCelebrityActive ? null : !isLogin && !homePageVideo && <NotAuthorizedUserBanner />}
              </GridWrap>
              <HomePageSlider />
            </Section>
          </Content>
        </Row>
      </Container>
    </>
  );
};

export default Main;
