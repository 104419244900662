import React, { useCallback, useState, useRef, useEffect } from "react";
import delay from "lodash/delay";
import Button from "../../../shared/Button";
import { colors } from "../../../styles/colors";
import { Icon } from "components/SvgIcons";
import { useRouter } from "next/router";

import {
  Container,
  ContentBlock,
  Desc,
  MainTitle,
  ContentWrap,
  VideoContainer,
  Video,
  VideoControls,
  Control,
} from "./styled";

export const NotAuthorizedUserVideo = ({ src }) => {
  const [isPlaying, setPlaying] = useState(false);
  const [isMuted, setMuted] = useState(true);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setPlaying(!isPlaying);
  };

  const toggleMute = (e) => {
    e.stopPropagation();
    videoRef.current.muted = !isMuted;
    setMuted(!isMuted);
  };

  const router = useRouter();
  const toRegistration = useCallback((e) => {
    e.stopPropagation();
    router.push("/registration");
  }, []);

  useEffect(() => {
    let observer;
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!window.IntersectionObserver) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio != 1) {
              if (videoRef.current) {
                videoRef.current.pause();
                setPlaying(false);
              }
            }
          });
        },
        { threshold: 0.1 }
      );
      observer.observe(videoRef.current);
    }

    delay(() => {
      videoRef.current?.play();
      setPlaying(true);
    }, 300);

    return () => {
      if (observer) {
        try {
          observer.unobserve(videoRef.current);
        } catch (e) {
          console.log("unobserve on unmount video error", e);
        }
      }
    };
  }, []);

  return (
    <Container onClick={togglePlay}>
      <VideoContainer>
        <Video ref={videoRef} autoPlay={true} muted={true} loop={true} playsInline={true} src={src}></Video>
      </VideoContainer>
      <ContentWrap>
        <ContentBlock>
          <MainTitle>A recipe marketplace for culinary creators</MainTitle>
          <Desc>
            <p>Buy amazing recipes and support your favorite culinary creators.</p>
            <p>No more scrolling through screenshots.</p>
            <p>All your recipes in one place.</p>
            <p>Ad free.</p>
          </Desc>
          <Button onClick={toRegistration} color={colors.RED} stylType="primary" text="Sign Up FREE" />
          <VideoControls>
            <Control>
              <Icon icon={isPlaying ? "pause" : "play_icon"} />
            </Control>
            <Control onClick={toggleMute}>
              <Icon icon={isMuted ? "unmute" : "mute"} />
            </Control>
          </VideoControls>
        </ContentBlock>
      </ContentWrap>
    </Container>
  );
};
